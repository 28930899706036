/**
 * @file It contains scripts for home view
 */
import { mapActions, mapGetters } from "vuex"
export default {
  name: "Home",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      channels      : "channels/channels",
      logoOfChannels: "channels/logoOfChannels"
    }),
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    logoOfChannel() {
      return this.logoOfChannels[this.channel.id]
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapActions({
      notify: "shared/notify"
    })
  },
  watch: {
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !newValue.enabled) {
          this.notify({
            type: "error",
            text: "92"
          })
        }
      }
    }
  }
}
